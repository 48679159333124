.Home {
  background-color: #fac238;
  background-image: linear-gradient(180deg, #fac238 50%, #f6a501 100%);

  &__photo {
    display: none;

    @include minMedia($tablet768) {
      background: #685f5a url("../img/home-bg.jpg") 100% 0/cover no-repeat;
      display: block;
      width: 46vw;
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;

      &:before {
        content: "";
        background-color: #685f5a;
        background-image: linear-gradient(180deg, #685f5a 50%, #2e2e2e 100%);
        display: block;
        width: 46vw;
        height: 100vh;
        opacity: .8;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
  }

  &__container {
    @include minMedia($tablet768) {
      padding-top: 13vh;
    }
  }

  @include maxMedia($mobile) {
    .heading br,
    .description br {
      display: none;
    }
  }
}