.main {
  @include maxMedia($mobile) {
    transform: none !important;
  }
}

.container {
  max-width: 1134px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.section {
  display: block;
  width: 100%;
  padding: 10vh 15px;
  position: relative;
  overflow: hidden;
  transition: opacity .5s ease;

  &:first-of-type {
    padding-top: 20vh;
  }

  @include minMedia($tablet768) {
    padding: 24vh 8vw 0;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
}

/* stylelint-disable */
@supports (padding: max(0px)) {
  .section {
    padding-left: unquote("max(15px, env(safe-area-inset-left))");
    padding-right: unquote("max(15px, env(safe-area-inset-right))");
  }
}
/* stylelint-enable */


/* Content */
.heading {
  margin-bottom: .3em;

  &--white {
    color: $white;
    text-shadow: 0 3px 10px rgba($black, .1);
  }
}

.description {
  font-weight: 300;
  margin-bottom: 1em;

  &--white {
    color: $white;
  }
}

.sota-group {
  &:before {
    content: "";
    background-repeat: no-repeat;
    background-size: auto;
    display: block;
    width: 507px;
    height: 543px;
    z-index: 1;
    position: absolute;
  }

  &.group-1--white:before {
    background-image: url("../img/sota-group-1--white.svg");
    top: -228px;
    right: -206px;
    transform: scale(.25);

    @include minMedia($tablet768) {
      top: -180px;
      right: -160px;
      transform: scale(.5);
    }

    @include minMedia($desktop1280) {
      top: -90px;
      right: -60px;
      transform: scale(1);
    }
  }

  &.group-1--orange:before {
    background-image: url("../img/sota-group-1--orange.svg");
    top: -225px;
    right: -206px;
    transform: scale(.25);

    @include minMedia($tablet768) {
      top: -180px;
      right: -160px;
      transform: scale(.5);
    }

    @include minMedia($desktop1280) {
      top: -90px;
      right: -60px;
      transform: scale(1);
    }
  }

  &.group-2--orange:before {
    background-image: url("../img/sota-group-2--orange.svg");
    bottom: -221px;
    right: -206px;
    transform: scale(.25);

    @include minMedia($tablet768) {
      bottom: -180px;
      right: -160px;
      transform: scale(.5);
    }

    @include minMedia($desktop1280) {
      bottom: -90px;
      right: -60px;
      transform: scale(1);
    }
  }
}

.btn {
  background-color: $white;
  color: $yellow;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  border-radius: 50px;
  padding: 15px 45px;
  margin-top: .75rem;
  box-shadow: none;
  transition: box-shadow .3s ease;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba($black, .2);
  }

  &.btn--alt {
    background-color: rgba(247, 177, 24, .15);
  }
}

.text-center {
  text-align: center;
}