.Advantages {
  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1rem;
    margin: 0;
    list-style: none;
  }

  &__item {
    font-size: fluidFontSize(21px, 26px);
    color: $text-color;
    font-weight: 300;
    padding-left: 50px;
    position: relative;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 1.5em;
    }

    @include minMedia($tablet768) {
      flex-basis: 47%;
      max-width: 47%;
    }

    @include minMedia($tablet1024) {
      flex-basis: 31%;
      max-width: 31%;
    }

    @include maxMedia($mobile) {
      br {
        display: none;
      }
    }

    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 5px;
    }

    &.icon-1:before {
      background-image: url("../img/advantages/1.svg");
    }

    &.icon-2:before {
      background-image: url("../img/advantages/2.svg");
    }

    &.icon-3:before {
      background-image: url("../img/advantages/3.svg");
    }

    &.icon-4:before {
      background-image: url("../img/advantages/4.svg");
    }

    &.icon-5:before {
      background-image: url("../img/advantages/5.svg");
    }

    &.icon-6:before {
      background-image: url("../img/advantages/6.svg");
    }
  }
}