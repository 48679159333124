.Pagination {
  display: none;

  @include minMedia($tablet768) {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0;
    margin: auto;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 2vw;
    z-index: 5;

    li {
      display: block;
      padding: 15px;
      margin: 0;
      position: relative;
    }

    a {
      background-color: transparent;
      background-image: url("../img/sota--orange.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      width: 24px;
      height: 21px;
      display: block;
      position: relative;
      line-height: 1;
      transition: all .25s ease;

      &:hover,
      &.active {
        background-image: url("../img/sota--orange--active.svg");
      }
    }
  }

  @include maxMedia($tablet1024) {
    left: auto;
    right: 1vw;
  }
}

.viewing-page-1 .Pagination a {
  background-image: url("../img/sota--white.svg");
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, .2));

  &:hover,
  &.active {
    background-image: url("../img/sota--white--active.svg");
  }
}
