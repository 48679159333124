/* slick slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:focus {
    outline: none;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
/* end slick slider styles */

/* Slider */
.Sliders-wrap {
  display: flex;
  flex-wrap: wrap;
}

.Slider-sync {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 3px;

  @include minMedia($desktop1280) {
    width: 55%;
  }

  @include maxMedia($tablet1024) {
    max-width: 400px;
  }

  @include maxMedia($tablet768) {
    max-width: 95%;
  }

  &__item {
    border-radius: 5px;
    overflow: hidden;
  }
}

.Slider-nav {
  width: 100%;
  height: 100% !important;
  padding: 0 40px;
  position: relative;

  @include maxMedia($tablet1024) {
    margin-top: 1rem;
  }

  @include minMedia($tablet768) {
    padding: 0;
  }

  @include minMedia($desktop1280) {
    width: 45%;
    padding-left: 1rem;
  }

  .slick-list {
    @include minMedia($desktop1280) {
      overflow: visible;
    }
  }

  &__item {
    max-width: 120px;
    opacity: 1;
    padding: 4px;
    transition: opacity .2s ease;
    position: relative;

    @include minMedia($tablet768) {
      max-width: 140px;
    }

    @include minMedia($tablet1024) {
      max-width: 150px;
      padding: 10px;
      cursor: pointer;
    }

    @include minMedia($desktop1280) {
      padding: 15px;

      &:nth-child(3n + 1) {
        bottom: -4%;
      }

      &:nth-child(3n + 2) {
        top: -2%;
        left: -6%;
      }

      &:nth-child(3n + 3) {
        bottom: -4%;
        left: -12%;
      }
    }

    &.slick-current {
      opacity: .7;
    }
  }

  .slick-arrow {
    background-color: transparent;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(50% - 12px);
    transform: rotate(90deg);
    border: 0;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 0;
    appearance: none;
    cursor: pointer;

    &.slick-prev {
      background-image: url("../img/down.svg");
      left: 0;

      @include minMedia($tablet768) {
        left: -40px;
      }
    }

    &.slick-next {
      background-image: url("../img/up.svg");
      right: 0;

      @include minMedia($tablet768) {
        right: -40px;
      }
    }
  }
}