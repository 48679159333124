* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: $base-font;
  font-weight: normal;
  font-size: 23px;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: $base-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  color: $text-color;
  word-wrap: break-word;
  background-color: $white;
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

::selection {
  background: $selection-bg;
  color: $white;
  text-shadow: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
