@include minMedia($tablet768) {
  body,
  html {
    overflow: hidden;
    transition: opacity .4s;
  }

  body,
  .page-wrapper,
  html {
    display: block;
    position: static;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .page-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding: 0;
    -webkit-transform-style: preserve-3d;
  }

  .page-wrapper .ops-section {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .disabled-onepage-scroll,
  .disabled-onepage-scroll .wrapper {
    overflow: auto;
  }

  .disabled-onepage-scroll .page-wrapper .ops-section {
    position: relative !important;
    top: auto !important;
  }

  .disabled-onepage-scroll .page-wrapper {
    transform: none !important;
    min-height: 100%;
  }

  .disabled-onepage-scroll .Pagination {
    display: none;
  }

  body.disabled-onepage-scroll,
  .disabled-onepage-scroll .page-wrapper,
  html {
    position: inherit;
  }
}