.inside-page,
.inside-page body {
  overflow: inherit;
  height: auto;
}

.inside-page body {
  // overflow-x: hidden;
  // position: relative;
}

.header {
  padding: 8vh 15px 5vh;

  @include minMedia($tablet768) {
    padding: 11vh 15px 6vh;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn {
    display: none;
    margin: 0;

    @include minMedia($tablet768) {
      display: inline-block;
    }
  }
}

.inside-page {
  h1 {
    font-size: 30px;
    line-height: 42px;
    margin: 0 0 20px;


    @include minMedia($tablet768) {
      font-size: 58px;
      line-height: 67px;
      margin: 0 0 40px;
    }
  }

  main {
    padding: 0 15px 216px;
    display: block;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      background: url("../img/sota-group-2--orange.svg") no-repeat 0 0 / auto;
      display: block;
      width: 439px;
      height: 464px;
      z-index: 1;
      position: absolute;
      bottom: -73px;
      right: -74px;
      transform-origin: right bottom;
      transform: scale(.78);

      @include minMedia($tablet768) {
        transform: scale(1);
      }
    }

    a:not(.btn) {
      text-decoration: underline;
    }

    p {
      line-height: 1.8;
    }

    ol {
      margin: 1rem 0;
      padding: 0;
      counter-reset: item;

      & > li {
        margin: 0 0 .7rem;
        list-style-type: none;
        counter-increment: item;

        &:before {
          display: inline-block;
          width: 1em;
          padding-right: 0.5em;
          font-weight: bold;
          text-align: right;
          content: counter(item) ".";
        }
      }

      ul {
        list-style-type: disc;
        padding: 0 0 0 1rem;
        margin: .75rem 0;
      }
    }
  }
}