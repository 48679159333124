.Header {
  background-color: transparent;
  display: block;
  width: 100%;
  padding: 5vh 15px 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;

  @include minMedia($tablet768) {
    padding: 11vh 8vw 0;
    position: absolute;
  }

  @include maxMedia($tablet768) {
    position: absolute;
  }
}