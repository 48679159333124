.LogoLink {
  display: inline-block;
  line-height: 1;
}

.Logo {
  max-width: 300px;
  height: 100%;

  &.Logo--alt {
    max-width: 100%;

    .Logo__path {
      fill: #f7b118;
    }
  }

  &__path {
    fill: #534f46;
    transition: all .3s ease;
  }
}

@include minMedia($mobile) {
  body:not(.viewing-page-1) .Logo .Logo__path {
    fill: $yellow;
  }
}
