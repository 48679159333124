$color: $yellow;
$size: 10px;
$time: 1;


.Loader {
  background-color: $white;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  transition: opacity .3s ease 2s;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__row {
    display: flex;
  }

  .arrow {
    width: 0;
    height: 0;
    margin: 0 (-$size / 2);
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: ($size * 1.8) solid $color;
    animation: blink $time + s infinite;

    &.down {
      transform: rotate(180deg);
    }

    @for $i from 1 through 18 {
      &.outer-#{$i} {
        animation-delay: -($time / 18) * $i + s;
      }
    }

    @for $i from 1 through 6 {
      &.inner-#{$i} {
        animation-delay: -($time / 6) * $i + s;
      }
    }
  }
}

@keyframes blink {
  0% { opacity: 0.1; }
  30% { opacity: 1; }
  100% { opacity: 0.1; }
}
