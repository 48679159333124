.Contacts {
  &:before {
    content: "";
    background-image: url("../img/sota-group-2--orange.svg");
    background-repeat: no-repeat;
    background-size: auto;
    display: block;
    width: 507px;
    height: 543px;
    z-index: 0;
    position: absolute;
    bottom: -228px;
    right: -206px;
    transform: scale(.25);

    @include minMedia($tablet768) {
      bottom: -180px;
      right: -160px;
      transform: scale(.5);
    }

    @include minMedia($desktop1280) {
      bottom: -90px;
      right: -60px;
      transform: scale(1);
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    &:first-of-type {
      padding-top: 3vh;
      padding-bottom: 4vh;
    }
  }

  &__item-label {
    font-size: fluidFontSize(18px, 23px);
    color: rgba($text-color, .5);
    font-weight: 300;
    width: 100%;
    padding-bottom: .3rem;

    @include minMedia($tablet1024) {
      text-align: right;
      padding-right: .8rem;
      max-width: 5em;
    }
  }

  &__item-info p {
    margin-bottom: .25em;
  }

  .terms {
    margin: 25px 0 0;

    @include minMedia($tablet768) {
      margin: 100px 0 0;
    }

    @include minMedia($tablet1024) {
      margin: 188px 0 0;
    }

    a {
      color: #f7b118;
      font-size: 18px;
      font-weight: 300;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}