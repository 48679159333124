/**
 *  Typography
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-font;
  color: $text-color;
  line-height: 1.1;
  font-weight: 400;
  margin: 0;
}

h1, h2 {
  font-size: fluidFontSize(45px, 95px);
}

h3 {
  font-size: fluidFontSize(28px, 45px);
}

h4 {
  font-size: fluidFontSize(25px, 30px);
}


ul {
  padding: 0;
  margin: .5rem 0 0;

  li {
    margin: 0 0 .6rem;
    font-size: fluidFontSize(18px, 23px);
    line-height: 1.5;
  }
}

ol {
  padding: 0 0 0 1rem;
  margin: .5rem 0 0;

  li {
    margin: 0 0 .6rem;
    font-size: fluidFontSize(18px, 23px);
    line-height: 1.5;
  }
}

p {
  font-size: fluidFontSize(18px, 23px);
  line-height: 1.5;
}

a {
  color: $text-color;
  text-decoration: none;
  transition: color .15s ease;

  &:hover {
    color: $yellow;
    text-decoration: none;
  }
}
/**
*  end Typography
*/
