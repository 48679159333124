/*--- font-size: fluidFontSize(16px, 22px); ---*/

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function fluidFontSize($f-min, $f-max, $w-min: 480, $w-max: 1920, $units: px) {
  $f-min: strip-unit($f-min);
  $f-max: strip-unit($f-max);
  $w-min: strip-unit($w-min);
  $w-max: strip-unit($w-max);
  $k: ($f-max - $f-min) / ($w-max - $w-min);
  $b: $f-min - $k * $w-min;
  $b: $b + $units;

  @return calc( #{$k} * 100vw + #{$b} );
}



/*--- @include maxMedia(768px) { padding: 0 1rem; } ---*/

@mixin maxMedia($media) {
  @if (type-of($media) != string) {
    @media only screen and (max-width: $media) {
      @content;
    }
  }
}

@mixin minMedia($media) {
  @if (type-of($media) != string) {
    @media only screen and (min-width: $media) {
      @content;
    }
  }
}